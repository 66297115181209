import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "checkbox"]
  static values = {
    confirmMsg: String
  }

  connect() {
  }

  toggle(e) {
    this.checkboxTarget.blur()

    if (this.confirmMsgValue.length > 0 && !confirm(this.confirmMsgValue)) {
      return e.preventDefault()
    }

    this.formTarget.requestSubmit()
  }
}
