import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "registrationNumberInput", "vrakDateInput" ]

  async setVrakDate() {
    const registrationNumber = this.registrationNumberInputTarget.value
    const vrakDateInput = this.vrakDateInputTarget

    if (registrationNumber.length === 7) {
      const date = await this.callRetriveVrakDate(registrationNumber)
      if (date !== null) {
        vrakDateInput.setAttribute('value', date)
        vrakDateInput.setAttribute('disabled', 'true')
        return
      }
    }
    vrakDateInput.removeAttribute('disabled')
  }

  async callRetriveVrakDate(registrationNumber) {
    const response = await fetch(`/batteries/${registrationNumber}/retrieve_vrak_date`, {
      method: "GET",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json"
      }
    });

    const data = await response.json();
    return data.date;
  }
}
