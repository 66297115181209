// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Ev_battery_controller from "./ev_battery_controller";
import Notices_controller from "./notices_controller";
import Quick_registration_batteries_controller from "./quick_registration_batteries_controller";
import Vrak_date_setter_controller from "./vrak_date_setter_controller";

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

application.register("ev_battery", Ev_battery_controller)
application.register("notices", Notices_controller)
application.register("quick_registration_batteries", Quick_registration_batteries_controller)
application.register("vrak_date_setter", Vrak_date_setter_controller)
